<template>
  <div>
    <b-card title="Stock">
      <b-row class="ml-auto">
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0 h-100"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                @change="sortingTable"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75 "
              >
              </b-form-select>
              <b-form-select
                @change="sortingTable"
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                @change="sortingTable"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5; "
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              @click="removeExitem"
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="admint"
        id="AdminsTable"
        striped
        hover
        responsive
        show-empty
        :busy.sync="isBusy"
        class="position-relative"
        :per-page="perPage"
        @sort-changed="fetchData"
        :current-page="currentPage"
        :items="fetchData"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #cell(name)="data">
         
            {{ data.item.translation.name }}
       
        </template>
        <template #cell(orderstatus)="data">
          <b-badge variant="primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(quantity)="data">
          <b-badge variant="primary">
            {{ data.value }}
          </b-badge>
        </template>
        <!-- admin actions -->
        <template #cell(actions)="items">
          <div>
            <!-- Show Order -->
            <b-button
              style="padding:7px; margin:6px; width:35px; height:35px;"
              :disabled="items.item.slug == null"
              v-if="authPermissions.includes('update') && items.item.is_new == false"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="submit"
              :to="{ path: '/Used/ShowProduct/variant/show/' + items.item.id }"
            >
              <feather-icon style="width:18px; height:18px;" icon="EyeIcon" />
            </b-button>
            <b-button
              style="padding:7px; margin:6px; width:35px; height:35px;"
              :disabled="items.item.slug == null"
              v-if="authPermissions.includes('update') && items.item.is_new == true"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="submit"
              :to="{ path: '/New/ShowProduct/variant/show/' + items.item.id }"
            >
              <feather-icon style="width:18px; height:18px;" icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="AdminsTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import flatPickr from "vue-flatpickr-component";


export default {
  components: {

    MonthPickerInput,
    flatPickr,
  },
  data() {
    return {
      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100, 200, 300, 400, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      isBusy: false,
      sortBy: "quantity",
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      btnclick: true,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // table fildes
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "sku",
          label: "sku",
        },
        {
          key: "name",
          label: "Product Name",
        },
        {
          key: "quantity",
          label: "quantity",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "vendor_code",
          label: "vendor code",
        },

        {
          key: "created_at",
          label: "created_at",
        },
      ],

      items: [],
      exportItems: [],
    };
  },
  created() {
    this.fetchData();
    this.authPermissions = this.$store.state.authPermissions
  },
  watch: {
    sortDirection: function() {
      this.sortingTable();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
  },
  computed: {
    // sortable in table by add (sortable: true) in field of table fields
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    // compute row of pagination
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    // fetch data of admins
    fetchData() {
      this.showDismissibleAlert = false;
      var url = null;
      if (this.filter == "") {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total;

          this.items = result.data.data.data;

          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered() {
      this.showDismissibleAlert = false;
      this.currentPage = 1;
      var url = null;
      if (this.filter == "") {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total;

          this.items = result.data.data.data;
          
          this.exportItems = this.items;
          
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // fetch data of sort result
    sortingTable() {
      this.showDismissibleAlert = false;
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }

      var url = null;
      if (this.filter == "") {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "reports/variants-pagi/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          this.totalRows = result.data.data.meta.total;

          this.items = result.data.data.data;
          this.exportItems = this.items;
         
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },

    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].avatar;
      }
    },
    // print function
    printEle() {
      window.print();
    },
    // generate pdf function
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].name,
          this.items[index].email,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [["NAME", "Email", "CREATED_AT", "UPDATED_AT"]],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    // delete admin function
    deleteAdmin(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete user.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("admins/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
</style>
<style lang="css" scoped>
.month-picker__container,
.month-picker--default {
  background-color: #fff;
}
[dir] .month-picker-input[data-v-201d773d] {
  height: 38px !important;
  width: 100% !important;
}
.month-picker {
  font-weight: 200;
  box-sizing: border-box;
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: sans-serif;
  overflow: hidden;
  justify-content: space-evenly;
  align-content: space-around;
}
.dountRevenu {
  height: 75% !important;
}
.month-picker-input-container[data-v-201d773d] {
  width: 100% !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
</style>
